import { Link, NavLink } from 'react-router-dom';
import React from 'react';

function Header() {
    const activeStyle = (status) => {
        return `nav-link link-dark` + (status.isActive ? ` text-decoration-underline` : ``);
    };

    return (
        <header className="border-bottom p-3 mb-3">
            <div className='container'>
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">

                    <div className="d-flex mx-4">
                        <Link className="fs-4 nav-link link-dark px-3" to="/">Salvinto</Link>
                    </div>

                    <nav className="d-flex flex-wrap justify-content-center">
                        <ul className="nav">
                            <li><NavLink className={activeStyle} to="/" end>Home</NavLink></li>
                            <li><NavLink className={activeStyle} to="about">About</NavLink></li>
                            <li><NavLink className={activeStyle} to="resume">Resume</NavLink></li>
                            <li><NavLink className={activeStyle} to="topics">Topics</NavLink></li>
                            <li><Link className="nav-link link-secondary" to="temp">Temp</Link></li>
                        </ul>   
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Header;
