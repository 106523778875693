import Header from './components/header'
import {Outlet} from 'react-router-dom'
import BreadcrumbWrapper from './components/breadcrumbwrapper';

function App() {
  return (
    <div>
      <Header>
      </Header>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <BreadcrumbWrapper />
            <Outlet/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
