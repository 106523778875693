import "bootstrap/dist/css/bootstrap.min.css";

import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import App from './App';
import Vector from './components/vector';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<App/>}>
      <Route index element={<Vector path="home.md"/>}/>
      <Route path="about" element={<Vector path="about.md"/>}/>
      <Route path="resume" element={<Vector path="resume.md"/>}/>
      <Route path="topics">
        <Route index element={<Vector path="/topics/index.md"/>}/>
        <Route path="2-2-the-web-and-http" element={<Vector path="/topics/2.2-the-web-and-http.md"/>}/>
        <Route path="2-4-domain-name-system" element={<Vector path="/topics/2.4-domain-name-system.md"/>}/>
        <Route path="2-3-search-tree" element={<Vector path="/topics/2-3-search-trees.md"/>}/>
      </Route>
      <Route path="temp" element={<Vector path="under-construction.md"/>}/>
    </Route>   
  </Routes>
  </BrowserRouter>
);
