import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from 'remark-math';
import rehypeMathjax from 'rehype-mathjax';

function h2Wrapper(props) {
    return <h3 style={{"marginBottom": "26px", "marginTop": "30px"}}>{props.children}</h3>;
}

function imgWrapper(props) {
    return <img src={props.src} alt={props.src} style={{objectFit:"contain", width:"90%"}} className="my-1 py-1">{props.children}</img>;
}

export default function Vector(props) {
    const [data, setData] = useState(`### Loading...`)

    useEffect(() => {
        const getFile = async () => {
            await fetch(props.path)
            .then((response) => response.text())
            .then((body) => {
                setData(body);
            })
            .catch((err) => console.log(err));
        };
        getFile();
    });

    // console.log(data);
    return (
        <ReactMarkdown remarkPlugins={[remarkGfm, remarkMath]}  rehypePlugins={[rehypeMathjax]}
                components={{h2: h2Wrapper, img: imgWrapper}}>
            {data}
        </ReactMarkdown>
        );

}
