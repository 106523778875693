import { useLocation } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

// cannot put in home page
export default function BreadcrumbWrapper() {
    const segments = useLocation().pathname.split("/");
    if (segments[1] !== "topics") {
        return;
    }

    let curr = ``;
    let links = [``];
    let nums = [];

    for (let i = 1; i < segments.length; i++) {
        curr += `/` + segments[i];
        links[i] = curr;
        nums[i-1] = i;
    }

    return (
        <Breadcrumb>
            {
            nums.map(num => 
                <Breadcrumb.Item href={links[num]} key={num} className="link-dark">
                    {segments[num]}
                </Breadcrumb.Item>)
            }
        </Breadcrumb>
    );
}
